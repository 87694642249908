import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare’s GDPR Commitment',
  description: 'We’re committed to helping Uploadcare customers and users understand, and where applicable, comply with the General Data Protection Regulation (GDPR).',
  header: 'Uploadcare’s GDPR Commitment'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We’re committed to helping Uploadcare customers and users understand, and where
applicable, comply with the General Data Protection Regulation (GDPR).`}</p>
    <p>{`Our team has adapted Uploadcare’s offerings, operations, and contractual
commitments to help customers comply with the regulation. Measures we have
implemented include:`}</p>
    <ul>
      <li parentName="ul">{`Investments in our `}<a parentName="li" {...{
          "href": "/about/security-whitepaper/"
        }}>{`security infrastructure`}</a>{` and
`}<a parentName="li" {...{
          "href": "/about/trust/"
        }}>{`certifications`}</a>{`.`}</li>
      <li parentName="ul">{`Updates to the `}<a parentName="li" {...{
          "href": "/about/terms/"
        }}>{`Terms of Service`}</a>{`, `}<a parentName="li" {...{
          "href": "/about/privacy-policy/"
        }}>{`Privacy Policy`}</a>{`,
`}<a parentName="li" {...{
          "href": "/about/cookie-policy/"
        }}>{`Cookie Policy`}</a>{`.`}</li>
      <li parentName="ul">{`Support for international data transfers by executing Standard
Contractual Clauses (including IDTA addendum) through our updated `}<a parentName="li" {...{
          "href": "/about/dpa/"
        }}>{`Data Processing
Agreement`}</a>{`. Check out our `}<a parentName="li" {...{
          "href": "/about/sub-processors/"
        }}>{`List of Sub-processors`}</a>{`,
`}<a parentName="li" {...{
          "href": "/about/tia/"
        }}>{`Transfer Impact Assessment`}</a>{` and `}<a parentName="li" {...{
          "href": "/about/law-enforcement/"
        }}>{`Transparency Report`}</a>{`.`}</li>
      <li parentName="ul">{`Offering data portability and data management tools including access,
correction, and removal of your personal data which you may exercise by
sending us a support ticket at `}<a parentName="li" {...{
          "href": "mailto:help@uploadcare.com"
        }}>{`help@uploadcare.com`}</a>{`.`}</li>
    </ul>
    <p>{`We also monitor the guidance on GDPR compliance from privacy-related regulatory
bodies and update our product features and contractual commitments accordingly.`}</p>
    <p>{`Our Terms of Service incorporate the DPA, but you can also
`}<a parentName="p" {...{
        "href": "/about/dpa/"
      }}>{`download it here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      